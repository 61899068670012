import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useAgeVerified = defineStore(
	"event",
	() => {
		const isAgeVerified = ref(false);
		function saveAgeVerified() {
			isAgeVerified.value = true;
		}
		return { isAgeVerified, saveAgeVerified };
	},
	{
		persist: {
			storage: persistedState.localStorage,
		},
	}
);